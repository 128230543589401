import { render, staticRenderFns } from "./Brochure.vue?vue&type=template&id=8ada67b0&scoped=true&"
import script from "./Brochure.vue?vue&type=script&lang=js&"
export * from "./Brochure.vue?vue&type=script&lang=js&"
import style0 from "./Brochure.vue?vue&type=style&index=0&id=8ada67b0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ada67b0",
  null
  
)

export default component.exports