<template>
  <div
    id="app"
    :class="{ 'has-mouse': hasMouse }"
    @touchstart="hasMouse = false"
  >
    <Flipbook
      class="flipbook"
      :pages="pages"
      :pagesHiRes="pagesHiRes"
      :startPage="pageNum"
      v-slot="flipbook"
      ref="flipbook"
      @flip-left-start="onFlipLeftStart"
      @flip-left-end="onFlipLeftEnd"
      @flip-right-start="onFlipRightStart"
      @flip-right-end="onFlipRightEnd"
      @zoom-start="onZoomStart"
      @zoom-end="onZoomEnd"
    >
      <div class="action-bar">
        <left-icon
          class="btn left"
          :class="{ disabled: !flipbook.canFlipLeft }"
          @click="flipbook.flipLeft"
        />
        <plus-icon
          class="btn plus"
          :class="{ disabled: !flipbook.canZoomIn }"
          @click="flipbook.zoomIn"
        />
        <span class="page-num">
          Pagina {{ flipbook.page }} de {{ flipbook.numPages }}
        </span>
        <minus-icon
          class="btn minus"
          :class="{ disabled: !flipbook.canZoomOut }"
          @click="flipbook.zoomOut"
        />
        <right-icon
          class="btn right"
          :class="{ disabled: !flipbook.canFlipRight }"
          @click="flipbook.flipRight"
        />
      </div>
    </Flipbook>
  </div>
</template>

<script>
import 'vue-material-design-icons/styles.css'
import LeftIcon from 'vue-material-design-icons/ChevronLeftCircle'
import RightIcon from 'vue-material-design-icons/ChevronRightCircle'
import PlusIcon from 'vue-material-design-icons/PlusCircle'
import MinusIcon from 'vue-material-design-icons/MinusCircle'
import Flipbook from 'flipbook-vue'

export default {
  name: 'app',
  components: { Flipbook, LeftIcon, RightIcon, PlusIcon, MinusIcon },
  data() {
      return {
        pages: [],
        pagesHiRes: [],
        hasMouse: true,
        pageNum: null
      };
  },
  methods: {
    onFlipLeftStart: function(page) {
      return console.log('flip-left-start', page);
    },
    onFlipLeftEnd: function(page) {
      console.log('flip-left-end', page);
      return window.location.hash = '#' + page;
    },
    onFlipRightStart: function(page) {
      return console.log('flip-right-start', page);
    },
    onFlipRightEnd: function(page) {
      console.log('flip-right-end', page);
      return window.location.hash = '#' + page;
    },
    onZoomStart: function(zoom) {
      return console.log('zoom-start', zoom);
    },
    onZoomEnd: function(zoom) {
      return console.log('zoom-end', zoom);
    },
    setPageFromHash: function() {
      var n;
      n = parseInt(window.location.hash.slice(1), 10);
      if (isFinite(n)) {
        return this.pageNum = n;
      }
    }
  }, 
  mounted() {
      window.addEventListener('keydown', (function(_this) {
        return function(ev) {
          var flipbook;
          flipbook = _this.$refs.flipbook;
          if (!flipbook) {
            return;
          }
          if (ev.keyCode === 37 && flipbook.canFlipLeft) {
            flipbook.flipLeft();
          }
          if (ev.keyCode === 39 && flipbook.canFlipRight) {
            return flipbook.flipRight();
          }
        };
      })(this));
      setTimeout(((function(_this) {
        return function() {
          _this.pages = [null, 
            'images/brochure/FolletoMaoNew1.jpeg',
            'images/brochure/FolletoMaoNew2.jpeg',
            'images/brochure/FolletoMaoNew3.jpeg', 
            'images/brochure/FolletoMaoNew4.jpeg',
            'images/brochure/FolletoMaoNew5.jpeg',
            'images/brochure/FolletoMaoNew6.jpeg',
            'images/brochure/FolletoMaoNew7.jpeg',
            'images/brochure/FolletoMaoNew8.jpeg',
            'images/brochure/FolletoMaoNew9.jpeg',
            'images/brochure/FolletoMaoNew10.jpeg',
            'images/brochure/FolletoMaoNew11.jpeg',
            'images/brochure/FolletoMaoNew12.jpeg',
            'images/brochure/FolletoMaoNew13.jpeg',
            'images/brochure/FolletoMaoNew14.jpeg',
            'images/brochure/FolletoMaoNew15.jpeg',
            'images/brochure/FolletoMaoNew16.jpeg',
            'images/brochure/FolletoMaoNew17.jpeg',
            'images/brochure/FolletoMaoNew18.jpeg',
            'images/brochure/FolletoMaoNew19.jpeg',
            'images/brochure/FolletoMaoNew20.jpeg',
            'images/brochure/FolletoMaoNew21.jpeg',
            'images/brochure/FolletoMaoNew22.jpeg',
            'images/brochure/FolletoMaoNew23.jpeg',
            'images/brochure/FolletoMaoNew24.jpeg',
            'images/brochure/FolletoMaoNew25.jpeg',
            'images/brochure/FolletoMaoNew26.jpeg',
            'images/brochure/FolletoMaoNew27.jpeg',
            'images/brochure/FolletoMaoNew28.jpeg',
            'images/brochure/FolletoMaoNew29.jpeg',
            'images/brochure/FolletoMaoNew30.jpeg',
            'images/brochure/FolletoMaoNew31.jpeg',
            'images/brochure/FolletoMaoNew32.jpeg',
            'images/brochure/FolletoMaoNew33.jpeg',
            'images/brochure/FolletoMaoNew34.jpeg',
            'images/brochure/FolletoMaoNew35.jpeg',
            'images/brochure/FolletoMaoNew36.jpeg',
            'images/brochure/FolletoMaoNew37.jpeg',
            'images/brochure/FolletoMaoNew38.jpeg',
            'images/brochure/FolletoMaoNew39.jpeg',
            'images/brochure/FolletoMaoNew40.jpeg',
            'images/brochure/FolletoMaoNew41.jpeg',
            'images/brochure/FolletoMaoNew42.jpeg',
            'images/brochure/FolletoMaoNew43.jpeg',
            'images/brochure/FolletoMaoNew44.jpeg',
            'images/brochure/FolletoMaoNew45.jpeg',
            'images/brochure/FolletoMaoNew46.jpeg',
            'images/brochure/FolletoMaoNew47.jpeg',
            'images/brochure/FolletoMaoNew48.jpeg',
            'images/brochure/FolletoMaoNew49.jpeg',
            'images/brochure/FolletoMaoNew50.jpeg',
            'images/brochure/FolletoMaoNew51.jpeg',
            'images/brochure/FolletoMaoNew52.jpeg',
            'images/brochure/FolletoMaoNew53.jpeg',
            'images/brochure/FolletoMaoNew54.jpeg',
            'images/brochure/FolletoMaoNew55.jpeg',
            'images/brochure/FolletoMaoNew56.jpeg',
            'images/brochure/FolletoMaoNew57.jpeg',
            'images/brochure/FolletoMaoNew58.jpeg',
            'images/brochure/FolletoMaoNew59.jpeg',
            'images/brochure/FolletoMaoNew60.jpeg',
            'images/brochure/FolletoMaoNew61.jpeg',
            'images/brochure/FolletoMaoNew62.jpeg',
            'images/brochure/FolletoMaoNew63.jpeg',
            'images/brochure/FolletoMaoNew64.jpeg',
            'images/brochure/FolletoMaoNew65.jpeg',
            'images/brochure/FolletoMaoNew66.jpeg',
            'images/brochure/FolletoMaoNew67.jpeg',
            'images/brochure/FolletoMaoNew68.jpeg',
            'images/brochure/FolletoMaoNew69.jpeg',
            'images/brochure/FolletoMaoNew70.jpeg',
            'images/brochure/FolletoMaoNew71.jpeg',
            'images/brochure/FolletoMaoNew72.jpeg',
            'images/brochure/FolletoMaoNew73.jpeg',
            'images/brochure/FolletoMaoNew74.jpeg',];
          return _this.pagesHiRes = [null, 'images-large/1.jpg', 'images-large/2.jpg', 'images-large/3.jpg', 'images-large/4.jpg', 'images-large/5.jpg', 'images-large/6.jpg'];
        };
      })(this)), 1);
      window.addEventListener('hashchange', this.setPageFromHash);
      return this.setPageFromHash();
    }
}
  
</script>

<style scoped>

.flipbook {
  width: 90vw;
  height: 90vh;
}
html, body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333;
  color: #ccc;
  overflow: hidden;
}

a {
  color: inherit;
}

.action-bar {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-bar .btn {
  font-size: 30px;
  color: #999;
}

.action-bar .btn svg {
  bottom: 0;
}

.action-bar .btn:not(:first-child) {
  margin-left: 10px;
}

.has-mouse .action-bar .btn:hover {
  color: #ccc;
  filter: drop-shadow(1px 1px 5px #000);
  cursor: pointer;
}

.action-bar .btn:active {
  filter: none !important;
}

.action-bar .btn.disabled {
  color: #666;
  pointer-events: none;
}

.action-bar .page-num {
  font-size: 12px;
  margin-left: 10px;
}

.flipbook .viewport {
  width: 90vw;
  height: calc(100vh - 50px - 40px);
}

.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
}

.credit {
  font-size: 12px;
  line-height: 20px;
  margin: 10px;
}
</style>
