<template>
    <div class="home section">
        <Modal v-model="showModal" title="">
            <img :src="img_modal" style="width: 100%;" alt="Planta Conjunto">
        </Modal>
        <Modal v-model="show_modal_info" title="">
            <!--<img :src="img_modal" style="width: 100%;" alt="Planta Conjunto">-->
            <table class="table table-bordered table-responsive tabla-modal">
                <thead>
                    <tr>
                        <th scope="col">Nivel</th>
                        <th>Departamento</th>
                        <th>Área Priv. M²</th>
                        <th>Jardín</th>
                        <th>Terraza 1 M²</th>
                        <th> Terraza 2 M²</th>
                        <th>Cajones de E</th>
                        <th>Cajones de E. M²</th>
                        <th>Bodega</th>
                        <th>Total M²</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="info in ver_info_departamento" :key="info.id">
                        <td>{{ info.nivel_num }}</td>
                        <td scope="row">{{ info.departamento }}</td>
                        <td>{{ info.area_pri }}</td>
                        <td>{{ info.jardin }}</td>
                        <td>{{ info.terraza_uno }}</td>
                        <td> {{ info.terraza_dos }} </td>
                        <td> {{ info.cajones_e }} </td>
                        <td> {{ info.cajones_e_me }} </td>
                        <td> {{ info.bodega }} </td>
                        <td> {{ info.total_m }} </td>

                    </tr>
                </tbody>
            </table>
        </Modal>
        <div class="datos-departamento-movil ocultar-pc ver-movil " style="width: 100%;">
            <div class="imagen-pequena movil p-3 text-center">
                <span class="texto-imagen-pequena">{{ texto_depa }}</span>
                <img :src="imagen_pequena" @click="imagenDepartamento()" alt="departamento-frontal">
                <div class="mt-md-4 mt-0 col-12">
                    <div class="col-12 p-0 text-center">
                        <h5>{{ ver_piso.nivel }}</h5>
                        <table class="table table-responsive table-bordered tabla-modal">
                                <thead>
                                    <tr v-for="info in ver_info_departamento" :key="info.id">
                                        <th v-if="info.nivel_num" scope="col">Nivel</th>
                                        <th v-if="info.nombre">Departamento</th>
                                        <th v-if="info.area_pri">Área Priv. M²</th>
                                        <th v-if="info.jardin">Jardín</th>
                                        <th v-if="info.terraza_uno">Terraza 1 M²</th>
                                        <th v-if="info.terraza_dos"> Terraza 2 M²</th>
                                        <th v-if="info.cajones_e">Cajones de E</th>
                                        <th v-if="info.cajones_e_me">Cajones de E. M²</th>
                                        <th v-if="info.bodega">Bodega</th>
                                        <th v-if="info.total_m">Total M²</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="info in ver_info_departamento" :key="info.id">
                                        <td v-if="info.nivel_num">{{ info.nivel_num }}</td>
                                        <td v-if="info.nombre" scope="row">{{ info.nombre }}</td>
                                        <td v-if="info.area_pri">{{ info.area_pri }}</td>
                                        <td v-if="info.jardin">{{ info.jardin }}</td>
                                        <td v-if="info.terraza_uno">{{ info.terraza_uno }}</td>
                                        <td v-if="info.terraza_dos"> {{ info.terraza_dos }} </td>
                                        <td v-if="info.cajones_e"> {{ info.cajones_e }} </td>
                                        <td v-if="info.cajones_e_me"> {{ info.cajones_e_me }} </td>
                                        <td v-if="info.bodega"> {{ info.bodega }} </td>
                                        <td v-if="info.total_m"> {{ info.total_m }} </td>

                                    </tr>
                                </tbody>
                            </table>
                    </div>
                </div>
            </div>
        </div>
        <div style="position: absolute; width: 100%;" v-if="ver_departamento">

            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 4000 2500" style="enable-background:new 0 0 4000 2500;" xml:space="preserve">
                <!--<polyline @click="showModalDate(pisos[0].piso_seis[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_seis[0].dpts[0])" class="st0" points="2000,570.3 2000.5,725.7 695,725.7 695,709.7 703.7,709.7 703.7,643.7 747.7,643.7 748.3,567.7 695,495.7 1360.3,495.7 1387,567.7 "/>
                <polygon @click="showModalDate(pisos[0].piso_cinco[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_cinco[0].dpts[0])" class="st0" points="695,725.7 1360,725.7 1388,787 2000.5,787 2000,956 695,956 695,939 705,939 704,871 750,873 750,787 "/>
                <polygon @click="showModalDate(pisos[0].piso_cuatro[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_cuatro[0].dpts[0])" class="st0" points="694,953.7 1359,953.7 1387,1015 1999.5,1015 1999,1184 694,1184 694,1167 704,1167 703,1099 749,1101 749,1015 "/>
                <polygon @click="showModalDate(pisos[0].piso_tres[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_tres[0].dpts[0])" class="st0" points="696,1185 1361,1185 1388,1228 1993,1228 1996,1417 1359,1417 696,1417 696,1399 704,1397 705,1330 747,1328 750,1228 "/>
                <polygon @click="showModalDate(pisos[0].piso_dos[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_dos[0].dpts[0])" class="st0" points="697,1417 1361,1417 1386,1446 1996,1446 1996,1643 697,1643 697,1629 705,1628 705,1560 749,1561 748,1446 "/>
                <polygon @click="showModalDate(pisos[0].piso_uno[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_uno[0].dpts[0])" class="st0" points="694,1643 1361,1643 1388,1667 2000,1667 2000,1759 1718,1759 1718,1787 1706,1787 1708,1833 607,1833 607,1756 748,1756 750,1667 "/>
                <polygon @click="showModalDate(pisos[0].piso_seis[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_seis[0].dpts[1])" class="st0" points="3319,495 2653,495 2627,567 2010,567 2010,725 3319,725 3319,711 3309,710 3309,641 3265,641 3264,568 "/>
                <polyline @click="showModalDate(pisos[0].piso_cinco[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_cinco[0].dpts[1])" class="st0" points="2012,788 2012,954 3318,954 3318,941 3308,940 3308,871 3264,871 3262,788 3318,725 2652,725 2624,788 "/>
                <polyline @click="showModalDate(pisos[0].piso_cuatro[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_cuatro[0].dpts[1])" class="st0" points="2006.5,1184.5 2006.5,1008 2624,1008 2653,954 3318,954 3263,1008 3265,1101 3309,1101 3311,1169.5 3318,1169.5 3318,1184.5 "/>
                <polygon @click="showModalDate(pisos[0].piso_tres[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_tres[0].dpts[1])" class="st0" points="2651,1183.5 3318.3,1183.5 3263.7,1227 3263,1327.7 3309,1327.7 3309.7,1399 3318.3,1399 3318.3,1412.3 2651,1415.7 2004.3,1415 2004.3,1227 2623.7,1227 "/>
                <polygon @click="showModalDate(pisos[0].piso_dos[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_dos[0].dpts[1])" class="st0" points="2653,1415 3319,1415 3264,1447 3265,1564 3309,1564 3309,1629 3319,1629 3319,1643 2007,1643 2007,1447 2628,1447 "/>
                <polygon @click="showModalDate(pisos[0].piso_uno[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_uno[0].dpts[1])" class="st0" points="2652,1643 3319,1643 3262,1669 3264,1760 3404,1760 3404,1836 2301,1836 2301,1758 2007,1760 2007,1666 2629,1666 "/>
                <polygon @click="showModalDate(pisos[0].piso_cero[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_cero[0].dpts[1])" class="st0" points="2464.5,1833.5 2464.5,2049 3416.5,2049.5 3416.5,1833.5 "/>
                <polygon @click="showModalDate(pisos[0].piso_cero[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_cero[0].dpts[0])" class="st0" points="1549.5,1833 599,1834 599,2049 1560,2049 "/>
                -->
                <polyline  @mouseleave="show_box_info = false" @click="ver_recorrido('http://maoluxurycondos.mx/3dn/#pano6574/175.3/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_seis[0].dpts[0])" class="st0" points="2000,570.3 2000.5,725.7 695,725.7 695,709.7 703.7,709.7 703.7,643.7 747.7,643.7 748.3,567.7 695,495.7 1360.3,495.7 1387,567.7 "/>
                <polygon  @mouseleave="show_box_info = false" @click="ver_recorrido('http://maoluxurycondos.mx/3dn/#pano6574/175.3/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_cinco[0].dpts[0])" class="st0" points="695,725.7 1360,725.7 1388,787 2000.5,787 2000,956 695,956 695,939 705,939 704,871 750,873 750,787 "/>
                <polygon @mouseleave="show_box_info = false" @click="ver_recorrido('http://maoluxurycondos.mx/3dn/#pano6574/175.3/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_cuatro[0].dpts[0])" class="st0" points="694,953.7 1359,953.7 1387,1015 1999.5,1015 1999,1184 694,1184 694,1167 704,1167 703,1099 749,1101 749,1015 "/>
                <polygon @mouseleave="show_box_info = false" @click="ver_recorrido('http://maoluxurycondos.mx/3dn/#pano6574/175.3/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_tres[0].dpts[0])" class="st0" points="696,1185 1361,1185 1388,1228 1993,1228 1996,1417 1359,1417 696,1417 696,1399 704,1397 705,1330 747,1328 750,1228 "/>
                <polygon @mouseleave="show_box_info = false" @click="ver_recorrido('http://maoluxurycondos.mx/3dn/#pano6574/175.3/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_dos[0].dpts[0])" class="st0" points="697,1417 1361,1417 1386,1446 1996,1446 1996,1643 697,1643 697,1629 705,1628 705,1560 749,1561 748,1446 "/>
                <polygon @mouseleave="show_box_info = false" @click="ver_recorrido('http://maoluxurycondos.mx/3dn/#pano4664/175.0/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_uno[0].dpts[0])" class="st0" points="694,1643 1361,1643 1388,1667 2000,1667 2000,1759 1718,1759 1718,1787 1706,1787 1708,1833 607,1833 607,1756 748,1756 750,1667 "/>
                <polygon @mouseleave="show_box_info = false" @click="ver_recorrido('http://maoluxurycondos.mx/3dn/#pano6215/175.0/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_seis[0].dpts[1])" class="st0" points="3319,495 2653,495 2627,567 2010,567 2010,725 3319,725 3319,711 3309,710 3309,641 3265,641 3264,568 "/>
                <polyline @mouseleave="show_box_info = false" @click="ver_recorrido('http://maoluxurycondos.mx/3dn/#pano6215/175.0/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_cinco[0].dpts[1])" class="st0" points="2012,788 2012,954 3318,954 3318,941 3308,940 3308,871 3264,871 3262,788 3318,725 2652,725 2624,788 "/>
                <polyline @mouseleave="show_box_info = false" @click="ver_recorrido('http://maoluxurycondos.mx/3dn/#pano6215/175.0/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_cuatro[0].dpts[1])" class="st0" points="2006.5,1184.5 2006.5,1008 2624,1008 2653,954 3318,954 3263,1008 3265,1101 3309,1101 3311,1169.5 3318,1169.5 3318,1184.5 "/>
                <polygon @mouseleave="show_box_info = false" @click="ver_recorrido('http://maoluxurycondos.mx/3dn/#pano6215/175.0/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_tres[0].dpts[1])" class="st0" points="2651,1183.5 3318.3,1183.5 3263.7,1227 3263,1327.7 3309,1327.7 3309.7,1399 3318.3,1399 3318.3,1412.3 2651,1415.7 2004.3,1415 2004.3,1227 2623.7,1227 "/>
                <polygon @mouseleave="show_box_info = false" @click="ver_recorrido('http://maoluxurycondos.mx/3dn/#pano6215/175.0/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_dos[0].dpts[1])" class="st0" points="2653,1415 3319,1415 3264,1447 3265,1564 3309,1564 3309,1629 3319,1629 3319,1643 2007,1643 2007,1447 2628,1447 "/>
                <polygon @mouseleave="show_box_info = false" @click="ver_recorrido('http://maoluxurycondos.mx/3dn/#pano5230/174.8/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_uno[0].dpts[1])" class="st0" points="2652,1643 3319,1643 3262,1669 3264,1760 3404,1760 3404,1836 2301,1836 2301,1758 2007,1760 2007,1666 2629,1666 "/>
                <polygon @mouseleave="show_box_info = false" @click="ver_recorrido('http://maoluxurycondos.mx/3dn/#pano3573/9.1/-0.5/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_cero[0].dpts[1])" class="st0" points="2464.5,1833.5 2464.5,2049 3416.5,2049.5 3416.5,1833.5 "/>
                <polygon @mouseleave="show_box_info = false" @click="ver_recorrido('http://maoluxurycondos.mx/3dn/#pano4325/9.4/-0.5/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_cero[0].dpts[0])" class="st0" points="1549.5,1833 599,1834 599,2049 1560,2049 "/>
                <polygon @mouseover="textoPop('Lobby')" v-popover:Lobby @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano3568/-2.0/-2.0/90.0')" class="st0" points="2302,2048 2481,2048 2481,1833 1538,1833 1538,2048 "/>
                <polygon @mouseover="textoPop('Estacionamiento')" v-popover:Estacionamiento @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano3067/0.3/0.0/90.0')" class="st0" points="263,2309 263,2195 2299,2195 3743,2195 3743,2309 "/>
                <polygon @mouseover="textoPop('Roof Garden')" v-popover:Roof_frente @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano8449/-2.0/-2.0/90.0')" points="907,393 907,193 1667,193 1677,249 1837,251 1837,211 2169,219 2169,251 2337,251 2349,193 3101,193 3101,393 " class="st0"/>

            </svg>
        </div>

        <div style="position: absolute; width: 100%;" v-if="!ver_departamento">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 4000 2500" style="enable-background:new 0 0 4000 2500;" xml:space="preserve">
                <!--<polygon @click="showModalDate(pisos[0].piso_seis[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_seis[0].dpts[1])" class="st0" points="1765,661 1722.5,641.5 2395.5,302 2395.5,479.5 1722.5,776 1722.5,732 1764,715 "/>
                <polygon @click="showModalDate(pisos[0].piso_cinco[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_cinco[0].dpts[1])" class="st0" points="1723,775.7 2395.7,480.3 2395.7,653.7 1723,908.3 1723,873 1763.7,857 1764.3,793 "/>
                <polygon @click="showModalDate(pisos[0].piso_cuatro[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_cuatro[0].dpts[1])" class="st0" points="1721.7,915 2395.7,665 2395.7,838.3 1721.7,1047.7 1721.7,1011.7 1765.7,999 1764.3,927.7 "/>
                <polygon @click="showModalDate(pisos[0].piso_tres[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_tres[0].dpts[1])" class="st0" points="1721,1055 2395,849.7 2395,1023 1721,1187 1721,1152.3 1764.3,1140.3 1764.3,1067 "/>
                <polygon @click="showModalDate(pisos[0].piso_dos[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_dos[0].dpts[1])" class="st0" points="2395.7,1034.3 1721,1195 1764.3,1203.7 1764.3,1281.7 1721,1293 1721,1325 2336.3,1220.3 2337,1191 2356.3,1187.7 2395.7,1198.3 "/>
                <polygon @click="showModalDate(pisos[0].piso_seis[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_seis[0].dpts[0])" class="st0" points="2417,459 2417,290 2992,0 3454,0 "/>
                <polygon @click="showModalDate(pisos[0].piso_cinco[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_cinco[0].dpts[0])" class="st0" points="2419,645 2419,471 3490,0 3761,0 3769,138 "/>
                <polygon @click="showModalDate(pisos[0].piso_cuatro[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_cuatro[0].dpts[0])" class="st0" points="2417,832 2417,658 3767,158 3779,211 3779,472 3768,430 3770,417 "/>
                <polygon @click="showModalDate(pisos[0].piso_tres[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_tres[0].dpts[0])" class="st0" points="2417,1017 2417,844 3767,433 3779,477 3779,737 3767,694 "/>
                <polygon @click="showModalDate(pisos[0].piso_dos[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_dos[0].dpts[0])" class="st0" points="2419,1031 3767,709 3777,744 3777,1007 3768,974 2419,1206 "/>
                <polygon @click="showModalDate(pisos[0].piso_uno[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_uno[0].dpts[0])" class="st0" points="3768.3,985.7 3779,1012.3 3779,1263.7 3754.3,1223.7 2387,1376.3 2357,1372.3 2357,1188.3 2415,1205.7 2415.7,1214.3 "/>
                <polygon @click="showModalDate(pisos[0].piso_uno[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_uno[0].dpts[1])" class="st0" points="1721.5,1333.5 2338,1229 2338,1360 2241,1339.5 2059.5,1360 2056,1425 1721.5,1427.5 "/>
                <polygon @click="showModalDate(pisos[0].piso_cero[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_cero[0].dpts[0])" class="st0" points="3755.3,1236.3 3755.3,1451.7 3629,1336.7 3629,1450.3 2685,1504.3 2685,1354 "/>
                <polygon @click="showModalDate(pisos[0].piso_cero[0].dpts)" @mouseleave="show_box_info = false" @mouseover="infoDepartamentos(pisos[0].piso_cero[0].dpts[1])" class="st0" points="1665.5,1466 1665.5,1550 1928,1550 2038,1480 2038,1426.5 "/>-->

                <polygon  @mouseleave="show_box_info = false" @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano6215/174.8/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_seis[0].dpts[1])" class="st0" points="1765,661 1722.5,641.5 2395.5,302 2395.5,479.5 1722.5,776 1722.5,732 1764,715 "/>
                <polygon  @mouseleave="show_box_info = false" @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano6215/174.8/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_cinco[0].dpts[1])" class="st0" points="1723,775.7 2395.7,480.3 2395.7,653.7 1723,908.3 1723,873 1763.7,857 1764.3,793 "/>
                <polygon  @mouseleave="show_box_info = false" @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano6215/174.8/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_cuatro[0].dpts[1])" class="st0" points="1721.7,915 2395.7,665 2395.7,838.3 1721.7,1047.7 1721.7,1011.7 1765.7,999 1764.3,927.7 "/>
                <polygon  @mouseleave="show_box_info = false" @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano6215/174.8/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_tres[0].dpts[1])" class="st0" points="1721,1055 2395,849.7 2395,1023 1721,1187 1721,1152.3 1764.3,1140.3 1764.3,1067 "/>
                <polygon  @mouseleave="show_box_info = false" @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano6215/174.8/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_dos[0].dpts[1])" class="st0" points="2395.7,1034.3 1721,1195 1764.3,1203.7 1764.3,1281.7 1721,1293 1721,1325 2336.3,1220.3 2337,1191 2356.3,1187.7 2395.7,1198.3 "/>
                <polygon  @mouseleave="show_box_info = false" @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano6574/174.8/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_seis[0].dpts[0])" class="st0" points="2417,459 2417,290 2992,0 3454,0 "/>
                <polygon  @mouseleave="show_box_info = false" @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano6574/174.8/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_cinco[0].dpts[0])" class="st0" points="2419,645 2419,471 3490,0 3761,0 3769,138 "/>
                <polygon  @mouseleave="show_box_info = false" @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano6574/174.8/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_cuatro[0].dpts[0])" class="st0" points="2417,832 2417,658 3767,158 3779,211 3779,472 3768,430 3770,417 "/>
                <polygon  @mouseleave="show_box_info = false" @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano6574/174.8/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_tres[0].dpts[0])" class="st0" points="2417,1017 2417,844 3767,433 3779,477 3779,737 3767,694 "/>
                <polygon  @mouseleave="show_box_info = false" @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano6574/174.8/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_dos[0].dpts[0])" class="st0" points="2419,1031 3767,709 3777,744 3777,1007 3768,974 2419,1206 "/>
                <polygon  @mouseleave="show_box_info = false" @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano4664/175.0/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_uno[0].dpts[0])" class="st0" points="3768.3,985.7 3779,1012.3 3779,1263.7 3754.3,1223.7 2387,1376.3 2357,1372.3 2357,1188.3 2415,1205.7 2415.7,1214.3 "/>
                <polygon  @mouseleave="show_box_info = false" @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano5230/175.3/0.6/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_uno[0].dpts[1])" class="st0" points="1721.5,1333.5 2338,1229 2338,1360 2241,1339.5 2059.5,1360 2056,1425 1721.5,1427.5 "/>
                <polygon  @mouseleave="show_box_info = false" @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano4325/0.0/0.0/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_cero[0].dpts[0])" class="st0" points="3755.3,1236.3 3755.3,1451.7 3629,1336.7 3629,1450.3 2685,1504.3 2685,1354 "/>
                <polygon  @mouseleave="show_box_info = false" @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano3573/-0.2/0.0/90.0')" @mouseover="infoDepartamentos(pisos[0].piso_cero[0].dpts[1])" class="st0" points="1665.5,1466 1665.5,1550 1928,1550 2038,1480 2038,1426.5" />
                <polyline @mouseover="textoPop('Estacionamiento')" v-popover:Estacionamiento @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano3067/0.3/0.0/90.0')" class="st0" points="3607,1565 3607,1815 1875,1763 1283,1735 1283,1623 3597,1565 "/>
                <polygon  @mouseover="textoPop('Alberca')" v-popover:planta_baja @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano3594/51.5/-5.3/90.0')" class="st0" points="2578.877,2365.931 3385.958,1960.891 1246.744,1801.875 235.641,1840.879 "/>
                <polyline @mouseover="textoPop('Roof Garden')" v-popover:Roof_atras @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano8449/-2.0/-2.0/90.0')" points="1765.5,610 2024.5,479 2024.5,463 1989,479.5 1947,456 1765.5,548.5 1765.5,608 " class="st0"/>
                <polygon  @mouseover="textoPop('Lobby')" class="st0" v-popover:Lobby @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano3568/-2.0/-2.0/90.0')" points="2078.5,1519 2140,1510.5 2144,1506.5 2187.5,1504 2204,1511.5 2240.5,1511.5 2240.5,1407 2078.5,1407 "/>
            </svg>
        </div>
        <popover class="text-center popover" name="Estacionamiento" event="hover">
            <span>{{ tipo_pop_atras }}</span>
        </popover>
        <popover class="text-center popover" name="Lobby" event="hover">
            <span>{{ tipo_pop_atras }}</span>
        </popover>
        <popover class="text-center popover" name="planta_baja" event="hover">
            <span>{{ tipo_pop_atras }}</span>
        </popover>
        <popover class="text-center popover" name="Roof_frente" event="hover">
            <span>{{ tipo_pop_atras }}</span>
        </popover>
        <popover class="text-center popover" name="Roof_atras" event="hover">
            <span>{{ tipo_pop_atras }}</span>
        </popover>

        
        <div class="imagen-pequena ocultar-movil">
            <img :src="imagen_pequena" @click="imagenDepartamento()" alt="departamento-frontal">
            <span class="texto-imagen-pequena">{{ texto_depa }}</span>
        </div>
        <div class="row m-0 imagen-departamento text-right">
            <img class="img-fondo" :src="imagen_grande" alt="departamento-frontal">
        </div>

        <section>
             <div class="container info-tabla ocultar-movil">
                 <div class="row">
                     <div class="col-12">
                        <transition name="fade">
                            <table v-if="show_box_info" class="table table-bordered tabla-modal">
                                <thead>
                                    <tr v-for="info in ver_info_departamento" :key="info.id">
                                        <th v-if="info.nivel_num" scope="col">Nivel</th>
                                        <th v-if="info.nombre">Departamento</th>
                                        <th v-if="info.area_pri">Área Priv. M²</th>
                                        <th v-if="info.jardin">Jardín</th>
                                        <th v-if="info.terraza_uno">Terraza 1 M²</th>
                                        <th v-if="info.terraza_dos"> Terraza 2 M²</th>
                                        <th v-if="info.cajones_e">Cajones de E</th>
                                        <th v-if="info.cajones_e_me">Cajones de E. M²</th>
                                        <th v-if="info.bodega">Bodega</th>
                                        <th v-if="info.total_m">Total M²</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="info in ver_info_departamento" :key="info.id">
                                        <td v-if="info.nivel_num">{{ info.nivel_num }}</td>
                                        <td v-if="info.nombre" scope="row">{{ info.nombre }}</td>
                                        <td v-if="info.area_pri">{{ info.area_pri }}</td>
                                        <td v-if="info.jardin">{{ info.jardin }}</td>
                                        <td v-if="info.terraza_uno">{{ info.terraza_uno }}</td>
                                        <td v-if="info.terraza_dos"> {{ info.terraza_dos }} </td>
                                        <td v-if="info.cajones_e"> {{ info.cajones_e }} </td>
                                        <td v-if="info.cajones_e_me"> {{ info.cajones_e_me }} </td>
                                        <td v-if="info.bodega"> {{ info.bodega }} </td>
                                        <td v-if="info.total_m"> {{ info.total_m }} </td>

                                    </tr>
                                </tbody>
                            </table>
                        </transition>
                     </div>
                 </div>
             </div>
        </section>
        <div class="container">
            <div class="row col-12 d-flex justify-content-center">
                <div class="col-4 text-center">
                    <button type="submit" @click="openModal('images/DISPONIBILIDAD MAO JULIO-2021.jpg')" class="btn btn-primary btn-dorado mt-3 mb-5">Ver Disponibilidad</button>
                </div>
                <div class="col-4 text-center">
                    <button type="submit" @click="openModal('images/FORMAS DE PAGO MAO.jpg')" class="btn btn-primary btn-dorado mt-3 mb-5">Formas de Pago</button>
                </div>
            </div>
        </div>

        <section >
            <div class="row p-5 col-12 imagenes-plantas d-flex justify-content-between" data-aos="fade-down" data-aos-once="true" data-aos-duration="1200" data-aos-anchor-position ="top-bottom">
                <div class="col-md-4 col-12 text-center" style="width:35%;">
                    <img style="width:35%;" @click="openModal('images/plantas/big/Planta Conjunto.png')" src="../assets/images/plantas/Planta Conjunto.png" alt="Planta Conjunto">
                    <h4 class="mt-3">Conjunto</h4>
                </div>
                <div class="col-md-4 col-12 text-center">
                    <img @click="openModal('images/plantas/big/Planta Lobby.png')"  src="../assets/images/plantas/Planta Lobby.png" alt="Planta Lobby">
                    <h4 class="mt-3">Lobby</h4>
                </div>
                <div class="col-md-4 col-12 text-center">
                    <img @click="openModal('images/plantas/big/Planta Nivel 2.png')" src="../assets/images/plantas/Planta Nivel 2.png" alt="Planta Nivel 2">
                    <h4 class="mt-3">Planta nivel 2</h4>
                </div>

                <div class="w-100 mt-4"></div>
                <div class="col-md-4 col-12 text-center">
                    <img style="width:35%;" @click="ver_recorrido('https://maoluxurycondos.mx/3dn/#pano3067/-122.4/1.6/90.0')" src="../assets/images/plantas/estacionamiento.png" alt="Planta Conjunto">
                    <h4 class="mt-3">Estacionamiento</h4>
                    <!--@click="openModal('images/plantas/big/estacionamiento.png')"-->
                </div>
                <div class="col-md-4 col-12 text-center">
                    <img @click="openModal('images/plantas/big/Planta Nivel 3-7.png')" src="../assets/images/plantas/Planta Nivel 3-7.png" alt="Planta Nivel 3-7">
                    <h4 class="mt-3">Planta nivel 3-7</h4>
                </div>
                <div class="col-md-4 col-12 text-center">
                    <img @click="openModal('images/plantas/big/Planta Roof Garden.png')" src="../assets/images/plantas/Planta Roof Garden.png" alt="Planta Roof Garden">
                    <h4 class="mt-3">Roof Garden</h4>
                </div>
            </div>
        </section>

        <section>
            <div class="row p-0 col-lg-12 col-xl-12 pr-0 texto-extra-departamentos m-0">
                <div class="col-md-12 col-lg-12 col-xl-4 col-12 fondo-mujer-departamento" data-aos="fade-down" data-aos-once="true" data-aos-duration="1200" data-aos-delay="500">
                </div>
                <div class="col-12 col-md-12 col-lg-12 col-xl-8 textos p-0 pr-2">
                    <div class="fondo-lineas-amarillas" ></div>
                    <div class="row justify-content-center align-items-center col-12 texto-departamentos p-md-2 m-md-2 p-0 m-0">
                        <div class="col-md-8 col-12 ">
                            <h3 class="titulo yate" data-aos="fade-down" data-aos-once="true" data-aos-duration="1200" data-aos-delay="500">
                                YACHT CLUB
                            </h3>
                            <p data-aos="fade-down" data-aos-once="true" data-aos-duration="1200" data-aos-delay="500">
                                El innovador concepto único de <b>MÃO</b>, contara con embarcaciones tipo fractional para que puedas disfrutar de las maravillas naturales que rodean Cancún desde la comodidad de tu hogar.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 palmera-izquierda ocultar-movil">
                        <img src="../assets/images/palmera-izquierda.png" alt="Hoja Palmera">
                    </div>
                </div>
            </div>
        </section>
  </div>
  
</template>


<script>
import VueModal from '@kouts/vue-modal';
import '@kouts/vue-modal/dist/vue-modal.css';
export default {
    components: {
    'Modal': VueModal
  },
  data() {
        return {
            showModal: false,
            show_modal_info: false,
            show_box_info: false,
            show: {
                nivel_num:true,
                nombre:true,
                area_pri: true, 
                jardin: true,
                terraza_uno: true,
                terraza_dos: true,
                cajones_e: true,
                cajones_e_me: true,
                bodega: true,
                total_m: true,
            },
            img_modal:'',
            texto_depa: 'POSTERIOR',
            imagen_grande: require('../assets/images/amenidades/1. Fachada Frontal Diurna.jpg'),
            imagen_frontal_tmbrl: require('../assets/images/1. Fachada Frontal Diurna_tmbrl.png'),
            // imagen_pequena: require('../assets/images/Fachada-Posterior.jpg'),
            imagen_pequena: require('../assets/images/Fachada_Posterior_tmbrl.png'),
            ver_departamento: true,
            ver_info_departamento: [],
            pisos: [ {
                piso_seis: [ {
                        id: 7,
                        nivel: "Nivel Siete",
                        dpts: [
                        {   id:1,
                            nombre: "701",
                            m_cuadrados: "625.23. m²",
                            nivel: "Nivel Siete",
                            nivel_num: 7,
                            departamento: "701",
                            area_pri: "479.34",
                            jardin: "",
                            terraza_uno: "51.56",
                            terraza_dos: "26.14",
                            cajones_e: "4",
                            cajones_e_me: "61.56",
                            bodega: "6.63",
                            total_m: "625.23"},
                        {   id:2,
                            nombre: "702",
                            m_cuadrados: "625.23. m²",
                            nivel: "Nivel Siete",
                            nivel_num: 7,
                            departamento: "702",
                            area_pri: "479.34",
                            jardin: "",
                            terraza_uno: "51.56",
                            terraza_dos: "26.14",
                            cajones_e: "4",
                            cajones_e_me: "61.56",
                            bodega: "6.63",
                            total_m: "625.23"},
                    ]
                }],
                piso_cinco: [ {
                        id: 6,
                        nivel: "Nivel Seis",
                        dpts: [
                        {   id:1,
                            nombre: "601",
                            m_cuadrados: "625.23. m²",
                            nivel: "Nivel Seis",
                            nivel_num: 6,
                            departamento: "601",
                            area_pri: "479.34",
                            jardin: "",
                            terraza_uno: "51.56",
                            terraza_dos: "26.14",
                            cajones_e: "4",
                            cajones_e_me: "61.56",
                            bodega: "6.63",
                            total_m: "625.23"
                        },
                        {   id:2,
                            nombre: "602",
                            m_cuadrados: "625.23. m²",
                            nivel: "Nivel Seis",
                            nivel_num: 6,
                            departamento: "602",
                            area_pri: "479.34",
                            jardin: "",
                            terraza_uno: "51.56",
                            terraza_dos: "26.14",
                            cajones_e: "4",
                            cajones_e_me: "61.56",
                            bodega: "6.63",
                            total_m: "625.23"},
                    ]
                }],
                piso_cuatro: [ {
                        id: 5,
                        nivel: "Nivel Cinco",
                        dpts: [
                        {   id:1,
                            nombre: "501",
                            m_cuadrados: "625.23. m²",
                            nivel: "Nivel Cinco",
                            nivel_num: 5,
                            departamento: "501",
                            area_pri: "479.34",
                            jardin: "",
                            terraza_uno: "51.56",
                            terraza_dos: "26.14",
                            cajones_e: "4",
                            cajones_e_me: "61.56",
                            bodega: "6.63",
                            total_m: "625.23"
                        },
                        {   id:2, nombre: "502",
                            m_cuadrados: "625.23. m²",
                            nivel: "Nivel Cinco",
                            nivel_num: 5,
                            departamento: "501",
                            area_pri: "479.34",
                            jardin: "",
                            terraza_uno: "51.56",
                            terraza_dos: "26.14",
                            cajones_e: "4",
                            cajones_e_me: "61.56",
                            bodega: "6.63",
                            total_m: "625.23"},
                    ]
                }],
                piso_tres: [ {
                        id: 4,
                        nivel: "Nivel Cuatro",
                        dpts: [
                        {   id:1,
                            nombre: "401",
                            m_cuadrados: "625.23. m²",
                            nivel: "Nivel Cuatro",
                            nivel_num: 4,
                            departamento: "401",
                            area_pri: "479.34",
                            jardin: "",
                            terraza_uno: "51.56",
                            terraza_dos: "26.14",
                            cajones_e: "4",
                            cajones_e_me: "61.56",
                            bodega: "6.63",
                            total_m: "625.23"
                        },
                        {   id:2,
                            nombre: "402",
                            m_cuadrados: "625.23. m²",
                            nivel: "Nivel Cuatro",
                            nivel_num: 4,
                            departamento: "401",
                            area_pri: "479.34",
                            jardin: "",
                            terraza_uno: "51.56",
                            terraza_dos: "26.14",
                            cajones_e: "4",
                            cajones_e_me: "61.56",
                            bodega: "6.63",
                            total_m: "625.23"
                        },
                    ]
                }],
                piso_dos: [ {
                        id: 3,
                        nivel: "Nivel Tres",
                        dpts: [
                        {   id:1,
                            nombre: "301",
                            m_cuadrados: "625.23. m²",
                            nivel: "Nivel Tres",
                            nivel_num: 3,
                            departamento: "301",
                            area_pri: "479.34",
                            jardin: "",
                            terraza_uno: "51.56",
                            terraza_dos: "26.14",
                            cajones_e: "4",
                            cajones_e_me: "61.56",
                            bodega: "6.63",
                            total_m: "625.23"
                        },
                        { 
                            id:2,
                            nombre: "302",
                            m_cuadrados: "625.23. m²",
                            nivel: "Nivel Tres",
                            nivel_num: 3,
                            departamento: "301",
                            area_pri: "479.34",
                            jardin: "",
                            terraza_uno: "51.56",
                            terraza_dos: "26.14",
                            cajones_e: "4",
                            cajones_e_me: "61.56",
                            bodega: "6.63",
                            total_m: "625.23"
                        },
                    ]
                }],
                piso_uno: [ {
                        id: 2,
                        nivel: "Nivel Dos",
                        dpts: [
                        {   id:1, 
                            nombre: "201",
                            m_cuadrados: "776.48. m² *",
                            nivel: "Nivel Dos",
                            nivel_num: 2,
                            departamento: "201",
                            area_pri: "479.34",
                            jardin: "",
                            terraza_uno: "91.56",
                            terraza_dos: "137.39",
                            cajones_e: "4",
                            cajones_e_me: "61.56",
                            bodega: "6.63",
                            total_m: "776.48"
                        },
                        {   id:2,
                            nombre: "202",
                            m_cuadrados: "776.48. m² *",
                            nivel: "Nivel Dos",
                            nivel_num: 2,
                            departamento: "201",
                            area_pri: "479.34",
                            jardin: "",
                            terraza_uno: "91.56",
                            terraza_dos: "137.39",
                            cajones_e: "4",
                            cajones_e_me: "61.56",
                            bodega: "6.63",
                            total_m: "776.48"
                        },
                    ]
                }],
                piso_cero: [ {
                        id: 1,
                        nivel: "Nivel Uno",
                        dpts: [
                            {   id:1, 
                                nombre: "GH1",
                                m_cuadrados: "726.66. m² *",
                                nivel: "Nivel Uno",
                                nivel_num: 1,
                                departamento: "GH1",
                                area_pri: "425.39",
                                jardin: "172.57",
                                terraza_uno: "60.51",
                                terraza_dos: "",
                                cajones_e: "4",
                                cajones_e_me: "61.56",
                                bodega: "6.63",
                                total_m: "726.66"
                            },
                            {   id:2,
                                nombre: "GH2",
                                m_cuadrados: "726.66. m² *",
                                nivel: "Nivel Uno",
                                nivel_num: 1,
                                departamento: "GH1",
                                area_pri: "425.39",
                                jardin: "172.57",
                                terraza_uno: "60.51",
                                terraza_dos: "",
                                cajones_e: "4",
                                cajones_e_me: "61.56",
                                bodega: "6.63",
                                total_m: "726.66"
                            },
                        ],

                }],
            },
        ],
        ver_piso: [{
            nombre: '',
            metros: '',
            nivel: ''
        }],
        tipo_pop: 'Roof Garden',
        tipo_pop_atras: 'Alberca'
        }
    },
    methods: {
        textoPop(texto) {
            this.tipo_pop_atras = texto 
        },
        ver_recorrido(url) {
            window.open(url, '_blank');
        },
        infoDepartamentos(pisos){
            this.show_box_info = true;
            this.ver_info_departamento = [pisos];
            console.log(this.ver_info_departamento);

        },
        imagenDepartamento(){

            if(this.ver_departamento)
            {
                this.texto_depa='FRONTAL';
                this.imagen_grande = require('../assets/images/Fachada-Posterior.jpg');
                this.imagen_pequena = require('../assets/images/1. Fachada Frontal Diurna_tmbrl.png');
                this.tipo_pop = 'Alberca'
            }
            else{
                this.texto_depa='POSTERIOR';
                this.imagen_grande = require('../assets/images/amenidades/1. Fachada Frontal Diurna.jpg');
                this.imagen_pequena = require('../assets/images/Fachada_Posterior_tmbrl.png');
                this.tipo_pop = 'Roof Garden'
            }

            this.ver_departamento = !this.ver_departamento;
            
        },
        openModal(img){
            console.log(img);
            this.showModal = true;
            this.img_modal = img;
        },
        showModalDate(dpts){
            this.show_modal_info = true;
            this.ver_info_departamento = dpts;
            console.log(dpts);
        }
    },
    created() {
        this.ver_piso = this.pisos[0].piso_uno;
    }
}
</script>

<style >
    .fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    }
    .vm {
        max-width: 866px !important;
    }
</style>


